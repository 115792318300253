export const baseURL =
  process.env.REACT_APP_BACKEND_URL || "https://dev.core.quatrixglobal.com/";
export const googleApIKey = process.env.REACT_APP_GOOGLE_API_KEY || "";
export const flutterwavePublicKey =
  process.env.REACT_APP_FLUTTERWAVE_PUBLIC_KEY || "";
export const pontyMobileTraccar =
  process.env.REACT_APP_PONTY_MOBILE_TRACCAR_URL;
export const quatrixMobileTraccar =
  process.env.REACT_APP_QUATRIX_MOBILE_TRACCAR_URL;
export const pontyTraccarUrl = process.env.REACT_APP_PONTY_TRACCAR_URL;
export const quatrixTraccarUrl = process.env.REACT_APP_QUATRIX_TRACCAR_URL;
